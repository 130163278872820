<template>
  <div class="partners">
    <div class="container">
      <custom-title
        title="合作伙伴"
        :style="{ marginBottom: '40px' }"
      ></custom-title>
      <div class="list">
        <template v-for="index in 11">
          <div class="item" :key="index">
            <img :src="`/static/images/p${index + 79}.png`" alt="" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.partners {
  margin-top: 20px;
  padding-bottom: 40px;
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      margin-top: 10px;
      margin-right: 5px;
      img {
        height: 73px;
      }
    }
  }
}
</style>