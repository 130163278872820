<template>
  <div class="service">
    <custom-title title="热点服务" link="/farm_service/goods"></custom-title>
    <div class="service-list">
      <template v-for="(item, index) in goods">
        <div class="item" :key="item.goodsId + 'a'">
          <div class="pic">
            <router-link
              :to="{
                name: 'farm_service_serve_detail',
                params: { id: item.goodsId },
              }"
            >
              <img :src="images[index]" alt="" />
            </router-link>
          </div>
        </div>
        <div class="item" :key="item.goodsId + 'b'">
          <div class="info">
            <router-link to="">
              <div class="title">
                <a-icon type="caret-right" />{{ item.name }}
              </div>
              <div class="price">
                ¥<b>{{ item.price }}</b>
              </div>
              <p>商品品牌：{{ item.paramValues[0]?item.paramValues[0].paramValue:"未添加" }}</p>
              <p>商品分类：{{ item.paramValues[1]?item.paramValues[1].paramValue:"未设置" }}</p>
            </router-link>
          </div>
        </div>
      </template>
      <div class="item">
        <div class="more">
          <router-link to="/farm_service/serves">
            <a-icon
              theme="filled"
              type="plus-circle"
              :style="{
                color: '#4bc948',
                fontSize: '60px',
              }"
            />
            <div class="text">查看更多服务</div>
          </router-link>
        </div>
      </div>
      <div class="item">
        <div class="tips">
          <p>农业社会化服务平台</p>
          <p>为您提供最专业的服务</p>
        </div>
      </div>
      <template v-for="(item, index) in serves">
        <div class="item" :key="item.goodsId + 'c'">
          <div class="pic">
            <router-link to="">
              <img :src="images[index + 2]" alt="" />
            </router-link>
          </div>
        </div>
        <div class="item" :key="item.goodsId + 'd'">
          <div class="info">
            <router-link to="">
              <div class="title">
                <a-icon type="caret-right" />{{ item.name }}
              </div>
              <div class="price">
                ¥<b>{{ item.price }}</b
                >元
              </div>
              <p>服务分类：{{ item.paramValues[0]?item.paramValues[0].paramValue:"未添加" }}</p>
              <p>服务范围：{{ item.paramValues[1]?item.paramValues[1].paramValue:"未设置" }}</p>
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import fw0 from "@/assets/images/fw0.png";
import fw1 from "@/assets/images/fw1.png";
import fw2 from "@/assets/images/fw2.png";
import fw3 from "@/assets/images/fw3.png";
import Api from "@/api/farm_service";
export default {
  data() {
    return {
      loading: false,
      goods: [],
      serves: [],
      images: [fw0, fw1, fw2, fw3],
    };
  },
  components: {},
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      Promise.all([
        Api.goodsList({ pageSize: 2, pageNum: 1 }),
        Api.serveList({ pageSize: 2, pageNum: 1 }),
      ]).then((res) => {
        this.loading = false;
        this.goods = res[0].result.records;
        this.serves = res[1].result.records;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.service {
  .service-list {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    .item {
      flex: 20%;
      img {
        width: 100%;
        -o-transition: all 0.4s ease-in-out;
        -ms-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        &:hover {
          -webkit-transform: rotateY(180deg);
          -moz-transform: rotateY(180deg);
        }
      }
      .info {
        padding: 0 10px;
        a {
          text-decoration: none;
        }
        .title {
          font-size: 16px;
          line-height: 2em;
          color: #444;
          border-bottom: 1px solid #eee;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 10px;
        }
        .price {
          line-height: 3.5em;
          b {
            font-size: 2em;
          }
        }
        p {
          margin: 0;
          font-size: 14px;
          color: #9e9e9e;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 2em;
        }
      }
      .more {
        width: 100%;
        height: 100%;
        background: #008840;
        text-align: center;
        padding-top: 35px;
        .text {
          color: white;
          line-height: 3em;
        }
      }
      .tips {
        text-align: center;
        color: #444;
        font-size: 16px;
        padding-top: 3em;
        p {
          margin: 0;
          line-height: 2em;
          &:first-child {
            color: #008840;
          }
        }
      }
    }
  }
}
</style>
