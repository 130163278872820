<template>
  <div class="index">
    <div class="banner">
      <div class="container">
        <div class="parallax">
          <img src="~@/assets/images/peoples.png" v-prlx="{ reverse: true }" />
        </div>
        <div class="btns">
          <div class="item">
            <a href="http://112.30.212.13:9060/" target="_blank">
              <div class="icon">
                <img src="~@/assets/images/big-data.png" />
              </div>
              <div class="name">
                <p></p>
                大数据
              </div>
            </a>
          </div>
          <div class="item">
            <router-link to="">
              <div class="icon">
                <img src="~@/assets/images/cjt.png" />
              </div>
              <div class="name">我是村集体<br />我要托管</div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="">
              <div class="icon">
                <img src="~@/assets/images/jyzt.png" />
              </div>
              <div class="name">我是经营主体<br />我要入驻</div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="">
              <div class="icon">
                <img src="~@/assets/images/sj.png" />
              </div>
              <div class="name">
                <p></p>
                我是商家
              </div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/finance_insurance">
              <div class="icon">
                <img src="~@/assets/images/dk.png" />
              </div>
              <div class="name">
                <p></p>
                我要贷款
              </div>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/finance_insurance">
              <div class="icon">
                <img src="~@/assets/images/bx.png" />
              </div>
              <div class="name">
                <p></p>
                我要保险
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <case :style="{ marginTop: '100px' }" />
      <organization />
      <service />
      <finance-insurance />
    </div>
    <news />
    <partners />
  </div>
</template>

<script>
import peoples from "@/assets/images/peoples.png";
import News from "./news";
import Case from "./case";
import Organization from "./organization";
import Service from "./service";
import FinanceInsurance from "./finance_insurance";
import Partners from "./partners";
export default {
  components: {
    News,
    Case,
    Organization,
    Service,
    FinanceInsurance,
    Partners,
  },
  data() {
    return {
      peoples,
    };
  },
};
</script>

<style lang="scss" scoped>
.index {
  .banner {
    background: url("~@/assets/images/top-banner.png") no-repeat bottom center /
      cover;
    height: 470px;
    .container {
      position: relative;
      height: 470px;
      .parallax {
        height: 270px;
        width: 100%;
        position: absolute;
        bottom: 40px;
        left: 0;
        img {
          display: block;
          vertical-align: bottom;
          height: 270px;
        }
      }
      .btns {
        position: absolute;
        justify-content: space-between;
        bottom: -80px;
        display: flex;
        z-index: 1024;
        height: 160px;
        width: 100%;
        .item {
          background: white;
          border-radius: 8px;
          box-shadow: 0 0 10px #d2d2d2;
          flex: 1;
          margin-left: 20px;
          text-align: center;
          transition: 0.3s all;
          &:hover {
            background: #00944f;
            a {
              color: white;
            }
            img {
              filter: hue-rotate(240deg);
            }
          }
          a {
            color: #333333;
            text-decoration: none;
            display: block;
            padding-top: 20px;
            height: 100%;
            img {
              margin-bottom: 15px;
              height: 55px;
            }
          }
          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
