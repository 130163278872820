<template>
  <div class="finance_insurance">
    <custom-title title="金融保险"></custom-title>
    <div class="box">
      <a-row>
        <a-col :span="12">
          <router-link to="/finance_insurance">
            <img src="@/assets/images/jr1.png" alt="" />
          </router-link>
        </a-col>
        <a-col :span="12">
          <router-link to="/finance_insurance">
            <img src="@/assets/images/jr2.png" alt="" />
          </router-link>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
      current: 0,
    };
  },
  components: {},
};
</script>

<style scoped lang="scss">
.finance_insurance {
  margin-top: 15px;
  margin-bottom: 30px;
  .box {
    text-align: center;
    margin-top: 25px;
    img {
      width: 95%;
    }
  }
}
</style>
