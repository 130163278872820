<template>
  <div class="cases">
    <a-spin :spinning="loading">
      <custom-title title="托管实例" link="/trusteeships"></custom-title>
      <div class="lists">
        <trusteeship
          v-for="(item, index) in list"
          :pkey="index"
          :info="item"
          :key="index"
        />
      </div>
    </a-spin>
  </div>
</template>

<script>
import Trusteeship from "@/components/Trusteeship";
import Api from "@/api/project";
export default {
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  components: { Trusteeship },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      Api.list({ pageNum: 1, pageSize: 20 })
        .then((res) => {
          this.loading = false;
          let i = 0;
          res.result.records.forEach((item) => {
            if (item.contract && i < 12) {
              i++;
              this.list.push(item);
            }
          });
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.cases {
  margin-top: 25px;
  .lists {
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    box-sizing: border-box;
  }
}
</style>
