<template>
  <div class="news">
    <a-spin :spinning="loading">
      <div class="container">
        <custom-title
          title="新闻资讯"
          :style="{ marginBottom: '40px' }"
        ></custom-title>
        <a-row :gutter="40">
          <a-col :span="10">
            <div class="banner">
              <router-link
                :to="{ name: 'news_detail', params: { id: top.articleId } }"
              >
                <div class="pic">
                  <img :src="banner" alt="" />
                </div>
                <div class="info">
                  <div class="time">{{ top.createTime }}</div>
                  <div class="title">{{ top.title }}</div>
                </div>
              </router-link>
            </div>
          </a-col>
          <a-col :span="14">
            <div class="news_list">
              <div class="item" :key="_new.articleId" v-for="_new in list">
                <router-link
                  :to="{ name: 'news_detail', params: { id: _new.articleId } }"
                >
                  <div class="info">
                    <div class="time">{{ _new.createTime }}</div>
                    <div class="title">
                      <h4>{{ _new.title }}</h4>
                      <p>{{ replaceHtml(_new.content.substring(0, 50)) }}...</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-spin>
    <a-spin :spinning="loading1">
      <div class="container">
        <custom-title
          title="公示信息"
          :style="{ marginBottom: '40px' }"
        ></custom-title>
        <div class="news_list">
          <a-row :gutter="40">
            <a-col :span="10" :key="_new.articleId" v-for="_new in notice">
              <div class="item">
                <router-link
                  :to="{ name: 'news_detail', params: { id: _new.articleId } }"
                >
                  <div class="info">
                    <div class="time">{{ _new.createTime }}</div>
                    <div class="title">
                      <h4>{{ _new.title }}</h4>
                      <p>{{ replaceHtml(_new.content.substring(0, 50)) }}...</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import banner from "@/assets/images/news.png";
import Api from "@/api/news";
export default {
  data() {
    return {
      news: [],
      banner,
      notice: [],
      loading1: false,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    top() {
      return this.news.length > 0 ? this.news[0] : [];
    },
    list() {
      return this.news.slice(1);
    },
  },
  methods: {
    getList() {
      this.loading = true;
      this.loading1 = true;
      Api.list({ pageSize: 4, pageNum: 1 }).then((res) => {
        this.news = res.result.records;
        this.loading = false;
      });
      Api.list({ pageSize: 8, pageNum: 1, categoryId: 5 }).then((res) => {
        this.notice = res.result.records;
        this.loading1 = false;
      });
    },
    replaceHtml(str) {
      const reg = /<[^<>]+>/g;
      return str.replace(reg, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  background: #f2f2f2;
  padding: 20px 0 30px;
  .container {
    a {
      text-decoration: none;
    }
    .banner {
      .pic {
        text-align: center;
        img {
          width: 100%;
        }
      }
      .info {
        margin-top: 15px;
        margin-bottom: 0;
        .time {
          flex: 20%;
          background: #008840;
          line-height: 1.2em;
        }
      }
    }
    .info {
      display: flex;
      margin-bottom: 30px;
      .time {
        text-align: center;
        font-weight: bold;
        flex: 10%;
        color: white;
        font-size: 18px;
        line-height: 1.6em;
        padding: 10px 20px;
        background: #c5c5c5;
      }
      .title {
        flex: 80%;
        font-size: 18px;
        color: #333;
        padding-left: 10px;
        line-height: 1.8em;
      }
      h4,
      p {
        margin: 0;
      }
      h4 {
        font-size: 18px;
        color: #333;
      }
      p {
        color: #666;
        font-size: 14px;
        line-height: 1.6em;
        height: 2em;
      }
    }
  }
}
</style>
