<template>
  <div class="organization">
    <custom-title
      title="社会化服务组织名录"
      link="/organizations/chems"
    ></custom-title>
    <a-spin :spinning="loading">
      <div class="box">
        <a-row :gutter="30">
          <a-col :span="8">
            <div class="title new">社会化服务组织名录</div>
            <div class="list">
              <ul>
                <li v-for="(item, index) in shh" :key="index">
                  <router-link :to="`/organizations/detail/${item.chemsId}`">{{
                    item.chemsName
                  }}</router-link>
                </li>
              </ul>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="title hot">村集体组织名录</div>
            <div class="list">
              <ul>
                <li v-for="(item, index) in cjt" :key="index">
                  <router-link
                    :to="`/organizations/village/detail/${item.villageId}`"
                  >
                    {{
                      item.organizationName ||
                      item.village.countryName +
                        item.village.villageName +
                        item.principalName
                    }}</router-link
                  >
                </li>
              </ul>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="carousel">
              <a-carousel dotsClass="custom-dot" autoplay>
                <div v-for="(item, index) in 5" :key="index">
                  <div class="img-box">
                    <router-link to="">
                      <img src="@/assets/images/image1.png" alt="" />
                      <h4>凤台县勤恳农机服务专业合作社</h4>
                    </router-link>
                  </div>
                </div>
              </a-carousel>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/organization";
export default {
  data() {
    return {
      shh: [],
      cjt: [],
      loading: false,
    };
  },
  components: {},
  created() {
    this.list();
  },
  methods: {
    list() {
      this.loading = true;
      Promise.all([Api.newFive(), Api.newFiveVillage()])
        .then((res) => {
          this.shh = res[0];
          this.cjt = res[1];
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>

<style scoped lang="scss">
.organization {
  .box {
    margin-top: 15px;
    min-height: 200px;
    .title {
      padding-left: 35px;
      color: #444444;
      font-size: 18px;
      &.new {
        background: url("~@/assets/images/new.png") no-repeat left center;
      }
      &.hot {
        background: url("~@/assets/images/hot.png") no-repeat left center;
      }
    }
    .list {
      margin-top: 15px;
      li {
        list-style-position: inside;
        color: #008840;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 2.9em;
        a {
          color: #444;
        }
      }
    }
  }
  .carousel {
    .img-box {
      position: relative;
      img {
        width: 100%;
      }
      h4 {
        position: absolute;
        bottom: 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        left: 0;
        background: url("~@/assets/images/banner-title.png");
        height: 35px;
        line-height: 35px;
        text-indent: 1em;
        color: white;
        margin: 0;
        padding-right: 30%;
      }
    }
  }
}
</style>
<style lang="scss">
.ant-carousel .slick-dots.custom-dot {
  position: absolute;
  right: 10px;
  bottom: 25px;
  z-index: 2;
  width: auto;
  li {
    button {
      width: 9px;
      height: 9px;
      border-radius: 50%;
    }
    &.slick-active {
      button {
        background: #008840;
      }
    }
  }
}
</style>
